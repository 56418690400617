<template>
  <div class="worker-tab-inner">

    <b-row class="d-flex align-items-stretch">
      <b-col
        cols="12"
        lg="4"
      >
        <b-card
          body-class="pb-50"
        >
          <h6>Wynagrodzenie zasadnicze</h6>
          <h4 class="font-weight-bolder mb-1">
            2950 brutto
          </h4>

        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="4"
      >
        <b-card
          body-class="pb-50"
        >
          <h6>Zwolnienia chorobowe (2022 r.)</h6>
          <h4 class="font-weight-bolder mb-1">
            Ilość dni: 65
          </h4>

        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="4"
      >
        <b-card
          body-class="pb-50"
        >
          <h6>Urlopy (2022 r.)</h6>
          <h4 class="font-weight-bolder mb-1">
            Wykorzystane 23 dni z 26
          </h4>

        </b-card>
      </b-col>
    </b-row>

    <!-- wygeneruj -->
    <b-row>

      <b-col
        cols="12"
      >

        <b-card
          body-class="pb-50"
        >
          <h3>Wygeneruj</h3>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="m-1 ml-0"
          >
            pit 11
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="m-1"
          >
            zus zcna
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="m-1"
          >
            zus zua
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="m-1"
          >
            zus ziua
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="m-1"
          >
            zus zwua
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="m-1"
          >
            zus zza
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="m-1"
          >
            zus ria
          </b-button>

        </b-card>

      </b-col>

    </b-row>

    <!-- aktywne umowy -->
    <b-row>

      <b-col
        cols="12"
      >

        <b-card
          body-class="pb-50 mb-1"
        >
          <h3 class="mb-1">
            Aktywne umowy
          </h3>

          <b-link>Umowa o pracę z dnia 20.03.2022</b-link>

        </b-card>

      </b-col>

    </b-row>

    <!-- rachunki oczekujące na opłacenie -->
    <b-row>

      <b-col
        cols="12"
      >

        <b-card
          body-class="pb-50 mb-1"
        >
          <h3 class="mb-1">
            Rachunki oczekujące na opłacenie
          </h3>

          <p>Brak rachunków</p>

        </b-card>

      </b-col>

    </b-row>

  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BButton, BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
  },
}
</script>

<style lang="scss" scoped>

.bg-white {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  border-radius: 0.428rem;
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

</style>

<template>
  <div class="bg-white p-5 rounded">
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Role -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Rola użytkownika"
            label-for="user-role"
          >
            <v-select
              v-model="userData.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateRole"
    >
      Zapisz rolę
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Administrator', value: 'Administrator' },
      { label: 'Pracownik Administratora', value: 'AdministratorWorker' },
      { label: 'Właściciel Biura Rachunkowego', value: 'TaxOfficeOwner' },
      { label: 'Pracownik Biura Rachunkowego', value: 'Employee' },
      { label: 'Klient Biura Rachunkowego', value: 'TaxOfficeClient' },
      { label: 'Pracownik Klienta Biura Rachunkowego', value: 'TaxOfficeClientWorker' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {
    updateRole() {
      const token = localStorage.getItem('accessToken')
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      axios.put(`${process.env.VUE_APP_API_URL}User/${this.$route.params.id}`, {
        name: this.userData.name,
        type: this.userData.type,
      }, config)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

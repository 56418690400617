<template>
  <div class="worker-tab-inner">

    <!-- Listy płac -->
    <b-row>

      <b-col
        cols="12"
      >

        <b-card
          body-class="pb-50 mb-1"
        >
          <h3 class="mb-1">
            Listy płac
          </h3>

          <b-tabs>
            <b-tab title="2019">

              <div class="d-flex flex-column pt-2">
                <b-link
                  v-for="n in 12"
                  :to="{ name: 'single-payroll', params: { id: '123' }}"
                  class="pb-1"
                >
                  Lista płac {{ n }}/2019
                </b-link>

              </div>

            </b-tab>

            <b-tab title="2020">

              <div class="d-flex flex-column pt-2">
                <b-link
                  v-for="n in 12"
                  :to="{ name: 'single-payroll', params: { id: '123' }}"
                  class="pb-1"
                >
                  Lista płac {{ n }}/2020
                </b-link>

              </div>

            </b-tab>

            <b-tab title="2021">

              <div class="d-flex flex-column pt-2">
                <b-link
                  v-for="n in 12"
                  :to="{ name: 'single-payroll', params: { id: '123' }}"
                  class="pb-1"
                >
                  Lista płac {{ n }}/2021
                </b-link>

              </div>

            </b-tab>

            <b-tab title="2022">

              <div class="d-flex flex-column pt-2">
                <b-link
                  v-for="n in 3"
                  :to="{ name: 'single-payroll', params: { id: '123' }}"
                  class="pb-1"
                >
                  Lista płac {{ n }}/2022
                </b-link>

              </div>

            </b-tab>

          </b-tabs>

        </b-card>

      </b-col>

    </b-row>

  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BButton, BLink, BTabs, BTab, BCardText,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
    BTabs,
    BTab,
    BCardText,
  },
}
</script>

<style lang="scss" scoped>

.bg-white {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  border-radius: 0.428rem;
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

</style>
